"use client";
import Image from "next/image";
import LoginHandler from "./LoginHandler";
import { useRouter } from "next/navigation";
import React from "react";

interface LoginPageProps {
  searchParams: { [key: string]: string | string[] | undefined };
}

export default function LoginPage({ searchParams }: LoginPageProps) {
  const router = useRouter();

  return (
    <div className="relative flex min-h-screen flex-col mx-auto max-w-xl items-center bg-[#121212]">
      {/* Navbar */}
      <div className="fixed top-0 z-20 mx-auto flex h-[56px] w-full max-w-xl items-center justify-between bg-transparent">
        <button
          className="ml-4"
          onClick={() => {
            router.back();
          }}
        >
          <Image
            src="/images/main/ic_arrow_left_ou_b_g90.svg"
            alt="prev"
            width={24}
            height={24}
          />
        </button>
      </div>

      <div className="w-full">
        <Image
          src="/images/main/bg_login.png"
          alt="bg_image"
          fill
          className="object-cover"
        />

        <div className="relative top-[85px] z-10 flex flex-col items-center font-GBold text-[19px] text-white">
          <p>{"우리들의 물음으로"}</p>
          <p>{"우주를 물들이자."}</p>

          <Image
            src="/images/main/ic_avatar.svg"
            alt="main_c"
            width={230}
            height={230}
            className="mt-[60px]"
          />

          <LoginHandler
            userId={searchParams["user-id"] as string}
            accessToken={searchParams["access-token"] as string}
            refreshToken={searchParams["refresh-token"] as string}
          />
        </div>
      </div>
    </div>
  );
}
