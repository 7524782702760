"use client";

import React, { useEffect } from "react";
import { useRouter } from "next/navigation";
import { setTokens } from "@/utils/token";
import { setLocalStorage, getLocalStorage } from "@/utils/storage";
import Image from "next/image";
import { useAtom } from "jotai/index";
import { accessTokenAtom } from "@/store/global";

interface LoginHandlerProps {
  userId?: string;
  accessToken?: string;
  refreshToken?: string;
}

export default function LoginHandler({
  userId,
  accessToken,
  refreshToken,
}: LoginHandlerProps) {
  const router = useRouter();
  const [, setAuthToken] = useAtom(accessTokenAtom);

  useEffect(() => {
    if (userId && accessToken && refreshToken) {
      setTokens(accessToken, refreshToken);
      setAuthToken(accessToken);
      setLocalStorage("userId", userId);

      const redirectUrl = getLocalStorage("redirectUrl") as string;
      if (redirectUrl) {
        localStorage.removeItem("redirectUrl");
        router.replace(redirectUrl);
      } else {
        router.replace("/");
      }
    }
  }, [userId, accessToken, refreshToken, router]);

  const handleKakaoLogin = () => {
    window.location.assign(
      `${process.env.NEXT_PUBLIC_API_DOMAIN}/oauth2/authorization/kakao`,
    );
  };

  return (
    <div className="fixed bottom-[30px] w-full cursor-pointer px-[40px] max-w-xl">
      <div className="font-GLight text-[14px] text-white/70 text-center mb-6">
        {"로그인은 회원님의 글 관리를 위해 필요해요.\n카카오 계정으로 개인정보는 안전하게 보호되며,\n간편하게 로그인할 수 있어요."
          .split("\n")
          .map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
      </div>
      <Image
        src="/images/main/btn_login_kakao.svg"
        alt="btn_login_kakao"
        width={300}
        height={54}
        className="w-full object-contain"
        onClick={handleKakaoLogin}
      />
    </div>
  );
}
